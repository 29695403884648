import Service from "@/helpers/service";

export default {
  save(params) {
    return Service().post("Bom/master/create", params);
  },
  get(params) {
    return Service().get("Bom/master/main", {params});
  },
  getChildren(parent) {
    return Service().get("Bom/master/children/" + parent);
  },
  delete(code) {
    return Service().post("Bom/master/delete/" + code);
  },
  update(params) {
    return Service().post("Bom/master/update", params);
  },
  getChar(code) {
    return Service().get("Bom/master/characteristic/" + code);
  },
  clone(code) {
    return Service().post("Bom/master/clone/" + code);
  },
  saveChar(params, bomCode) {
    return Service().post("Bom/master/save_characteristic/" + bomCode, params);
  }
};
