<template>
  <div>
    <div>
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0"></h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--6">
        <div class="card">
            <div class="card-header mt--4 mb--4">
                <div class="row">
                    <div class="col">
                        <h3 class="mb-0">{{tt('bill_of_material')}}</h3>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="tableSearchBom" :placeholder="tt('search')" v-on:keyup="filterBom"/>                         
                        </div>
                    </div>
                    <!-- <div class="col text-right">
                        <base-button type="default" size="sm" class="float-right" @click="addNew()"><span><i class="el-icon-plus"></i></span></base-button>
                    </div> -->
                </div>
            </div>
            <div>
                <el-table
                  v-if="onLoad"
                  :data="bomData"
                  height="350px"
                  header-row-class-name="thead-light"
                  row-key="id"
                  border
                  lazy
                  :load="load"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                  <el-table-column label="Category" min-width="150px" sortable>
                    <template slot-scope="scope">
                      <span>{{ scope.row.bom_type_name }}</span>
                      <!-- <i class="el-icon-circle-plus-outline ml-1" @click="createChild(scope)"></i> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="Code" min-width="150px" sortable>
                    <template slot-scope="scope">
                      <span v-if="scope.row.equipment_code">{{ scope.row.equipment_code }} - {{ scope.row.equipment_name }}</span>
                      <span v-else>{{ scope.row.bom_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="description" label="Description" min-width="450px" sortable></el-table-column>
                  <!-- <el-table-column prop="name" label="By" min-width="125px" sortable></el-table-column> -->
                  <!-- <el-table-column prop="" label="Notes" min-width="125px" sortable></el-table-column> -->
                  <!-- <el-table-column label="Action" width="130">
                    <template slot-scope="scope">
                      <i class="el-icon-search mr-2" @click="showCharValueForm(scope)"></i>
                      <i class="el-icon-document-copy mr-2" @click="clone(scope)"></i>
                      <i class="el-icon-edit mr-2" @click="edit(scope)"></i>
                      <i class="el-icon-delete" @click="remove(scope)"></i>
                    </template>
                  </el-table-column> -->
                </el-table>
                <skeleton-loading v-else/>
            </div>
            <div class="card-footer">
                <custom-pagination :page-count="10" size="sm"></custom-pagination>
            </div>
        </div>
      </div>

      <validation-observer v-slot="{invalid}">
          <modal :show.sync="bomForm.show">
              <template slot="header">
                  <h5 class="modal-title">{{ tt('add_new')}}</h5>
              </template>
              <div>
                  <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                  <base-input :name="tt('type')" :placeholder="tt('type')" rules="required">
                    <el-select v-model="bom.type" :placeholder="tt('minimum_type_char', {total: 2})" class="mr-1" filterable :disabled="bomForm.type == 'update'"> 
                        <el-option v-for="type in bomTypes" :key="type.bom_type_code" :value="type.bom_type_code" :label="type.bom_type_name"> </el-option>
                    </el-select>
                  </base-input>
              </div>
              <div>
                  <label class="form-control-label">{{ tt('name') }} <span class="text-danger">*</span></label>
                  <base-input :name="tt('name')" :placeholder="tt('name')" v-model="bom.bom_name" rules="required"></base-input>
              </div>
              <div>
                  <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                  <base-input :name="tt('description')" :placeholder="tt('description')" v-model="bom.description" rules="required"></base-input>
              </div>
              <template slot="footer">
                  <base-button @click="bomForm.show = false">{{ tt('close')}}</base-button>
                  <base-button :disabled="bomForm.bomLoading || invalid" @click="save">
                      <span v-if="bomForm.bomLoading "><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('save')}}</span>
                  </base-button>
              </template>
          </modal>    
      </validation-observer>

      <modal :show.sync="charValueForm.show" size="lg">
        <template slot="header">
            <h5 class="modal-title">{{ tt('characteristic_value')}}</h5>
        </template>
        <div style="height:350px;overflow-x: scroll;" v-if="!charValueForm.tableChar">
            <table class="table align-items-center customTable">
                <thead class="card-header bg-primary">
                    <tr>
                      <th class="text-white">{{tt('characteristic')}}<span class="text-danger">&nbsp;*</span></th>
                      <th class="text-white">{{tt('value')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dinc, key) in bomCharValue.bom_type_characteristic_code">
                        <td>
                            {{bomCharValue.bom_characteristic_name[key]}}
                            <input type="hidden" v-model="bomCharValue.bom_type_characteristic_code[key]">
                            <input type="hidden" v-model="bomCharValue.bom_code[key]">
                        </td>
                        <td>
                            <el-input v-model="bomCharValue.characteristic_value[key]" class="bom" size="small"></el-input>
                            <button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-if="bomCharValue.single_or_multiple[key] == 2 && bomCharValue.code[key] == ''">
                              <i class="fa fa-plus-square"  aria-hidden="true" style="font-size: 15px" @click="addMultipleChar(key,bomCharValue.bom_characteristic_name[key],bomCharValue.bom_type_characteristic_code[key],bomCharValue.bom_code[key],bomCharValue.single_or_multiple[key])"></i>
                            </button>
                            <button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-if="bomCharValue.single_or_multiple[key] == 2 && bomCharValue.code[key] == 1">
                              <i class="fa fa-minus-square text-danger"  aria-hidden="true" style="font-size: 15px" @click="removeMultipleChar(key)"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
          <page-loading/>
        </div>
        <template slot="footer">
            <base-button @click="charValueForm.show = false">{{ tt('close')}}</base-button>
            <base-button type="default" class="mt-1 float-right" @click="saveChar()">
                <span v-if="!charValueForm.loadingSave">{{tt('save')}}</span>
                <span v-else><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
            </base-button>
        </template>
      </modal>
    </div>
    <!-- <noaccess v-else/> -->
  </div>
</template>
<script>
  import bomMaster from '@/services/bom/bomMaster.service';
  import bomType from '@/services/bom/bomType.service';
  import Api from '@/helpers/api';
  export default {
    data() {
      return {
        currentBomCode: '',
        bomForm: {
          show:false,
          bomLoading: false,
          type: '',
        },
        charValueForm:{
          show: false,
          table: false,
          tableChar: false,
          loadingSave:false,
        },
        onLoad: false,
        bomData:[],
        tableSearchBom:'',
        // dataChar:[],
        bomCharValue:{
          // id:[],
          code:[],
          bom_type_characteristic_code:[],
          // bom_characteristic_code:[],
          bom_characteristic_name:[],
          single_or_multiple:[],
          characteristic_value:[],
          bom_code:[],
          // bom_type_code:[],
        },
        bom:{
          bom_code: '',
          bom_name:'',
          description: '',
          type: '',
          parent_code: 0,
          bom_level: 1,
        },
        // bomCode:'',
        // handleClassCss:{
        //   hoverTrash:false,
        //   hoverPlus:false,
        //   hoverPencil:false,
        // },
        // setCurrentRow:{},
        // rowKey:[0],
        // props: {
        //   label: 'description',
        //   children: [],
        //   isLeaf: 'leaf',
        //   bom_code:'bom_code',
        //   bom_type_characteristic_code:'bom_type_characteristic_code',
        //   bom_type_code:'bom_type_code',
        //   parent_code:'parent_code'
        // },
        // dataNode:{},
        selectSearch: {
          loading:false,
          loadTimeout:null,
        },
        bomTypes:[],
      }
    },
    mounted(){
      this.get();
      this.getType();
    },
    methods: {
      get(){
        let context = this;
        Api(context, bomMaster.get({search: context.tableSearchBom})).onSuccess((response) => {
          context.bomData = response.data.data;
        }).onError((error) =>{
          context.bomData = [];
        }).onFinish(() =>{
          context.onLoad = true
        }).call()
      },
      load(tree, treeNode, resolve) {
        setTimeout(() => {
          let context = this;
          Api(context, bomMaster.getChildren(tree.bom_code)).onSuccess((response) => {
            resolve(response.data.data);
          }).onError((error) =>{

          }).call()
        }, 100)
      },
      getType(){ 
          let context = this;               
          Api(context, bomType.get(null)).onSuccess(function(response) {    
              context.bomTypes = response.data.data.data.data;       
          }).onError(function(error) {                    
              if (error.response.status == 404) {
                  context.bomTypes = [];
              }
          }).call()
      },
      showCharValueForm(scope){
        this.charValueForm.tableChar = true;
        this.charValueForm.show      = true;
        this.bom.bom_code            = scope.row.bom_code

        let context                  = this;
        
        Api(context, bomMaster.getChar(scope.row.bom_code)).onSuccess((response) => {
          // context.bomCharValue.id = []
          // context.bomCharValue.bom_characteristic_code = []
          context.bomCharValue.code = [];
          context.bomCharValue.bom_type_characteristic_code = []
          context.bomCharValue.bom_characteristic_name = []
          context.bomCharValue.single_or_multiple = []
          context.bomCharValue.characteristic_value = []
          context.bomCharValue.bom_code = []
          // context.bomCharValue.bom_type_code = []
          for(let i = 0; i < response.data.data.length;i++){
            // context.bomCharValue.id[i] = (response.data.data[i].id == null) ? '' : response.data.data[i].id;
            // context.bomCharValue.bom_characteristic_code[i] = (response.data.data[i].bom_characteristic_code == null) ? '' : response.data.data[i].bom_characteristic_code;
            context.bomCharValue.code[i] = (response.data.data[i].code == null) ? '': response.data.data[i].code;
            context.bomCharValue.bom_type_characteristic_code[i] = (response.data.data[i].bom_type_characteristic_code == null) ? '' : response.data.data[i].bom_type_characteristic_code;
            context.bomCharValue.bom_characteristic_name[i] = (response.data.data[i].bom_characteristic_name == null) ? '' : response.data.data[i].bom_characteristic_name;
            context.bomCharValue.single_or_multiple[i] = (response.data.data[i].single_or_multiple == null) ? '' : response.data.data[i].single_or_multiple;
            context.bomCharValue.characteristic_value[i] = (response.data.data[i].characteristic_value == null) ? '' : response.data.data[i].characteristic_value;
            context.bomCharValue.bom_code[i] = (response.data.data[i].bom_code == null) ? '' : response.data.data[i].bom_code;
            // context.bomCharValue.bom_type_code[i] = (response.data.data[i].bom_type_code == null) ? '' : response.data.data[i].bom_type_code;
          }
        }).onError((error) => {
          context.bomCharValue.code = [];
          context.bomCharValue.bom_type_characteristic_code = []
          context.bomCharValue.bom_characteristic_name = []
          context.bomCharValue.single_or_multiple = []
          context.bomCharValue.characteristic_value = []
          context.bomCharValue.bom_code = []
        }).onFinish(() => {
          context.charValueForm.tableChar = false;
        }).call()
      },
      save(){
        this.bomForm.bomLoading = true;
        let context             = this;

        let api = async() => {
          if (this.bomForm.type == 'create') {
            return Api(context, bomMaster.save(context.bom));
          } else {
            return Api(context, bomMaster.update(context.bom));
          }
        }

        api().then((api) => {
          api.onSuccess((response) => {
            context.$notify({
                message: response.data.message,                  
                type: 'success',
                verticalAlign: 'bottom', 
                horizontalAlign: 'left'
            });    
          }).onError((error)=>{
            context.$notify({
                message: error.response.data.message,                  
                type: 'danger',
                verticalAlign: 'bottom', 
                horizontalAlign: 'left'
            });
          }).onFinish(() =>{
            context.bomForm.bomLoading = false;
            context.bomForm.show       = false;
            context.bom.bom_level      = 1;
            context.bom.parent_code    = 0;
            context.bom.description    = '';
            context.bom.type           = '';
            context.get();
          }).call()
        })
      },
      createChild(scope) {
        this.bomForm.type    = 'create'
        this.bomForm.show    = true
        this.bom.parent_code = scope.row.bom_code
        this.bom.bom_level   = scope.row.bom_level + 1
      },
      addNew() {
        this.bomForm.type    = 'create'
        this.bomForm.show    = true
        this.bom.description = ''
        this.bom.type        = ''
        this.bom.parent_code = 0
        this.bom.bom_level   = 1
        this.bom.bom_code    = ''
      },
      edit(scope) {
        this.bomForm.type    = 'update'
        this.bomForm.show    = true
        this.bom.description = scope.row.description
        this.bom.type        = scope.row.bom_type_code
        this.bom.parent_code = scope.row.parent_code
        this.bom.bom_level   = scope.row.bom_level
        this.bom.bom_code    = scope.row.bom_code
      },
      clone(scope){
        this.bom.bom_code = scope.row.bom_code
        this.confirmDialog(this.tt("confirm_clone")).then((result) => {
          if(result.value){
            let context = this;
            Api(context, bomMaster.clone(context.bom.bom_code)).onSuccess((response) =>{
                context.$notify({
                    message: response.data.message,                  
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.get()
            }).onError((error) =>{
                context.$notify({
                    message: error.response.data.message,                  
                    type: 'danger',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
            }).call()
          }
        })
      },
      remove(scope) {
        this.bom.bom_code = scope.row.bom_code
        this.confirmDialog(this.tt("confirm_delete")).then((result) => {
          if(result.value){
            let context = this;
            Api(context, bomMaster.delete(this.bom.bom_code)).onSuccess((response) =>{
              context.$notify({
                  message: response.data.message,                  
                  type: 'success',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
              });
              context.get();
            }).onError((error) =>{
              context.$notify({
                  message: error.response.data.message,                  
                  type: 'danger',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
              });
            }).onFinish(() =>{
                context.get();
            }).call()
          }
        })
      },
      addMultipleChar(key, bom_characteristic_name, bom_type_characteristic_code, bom_code, single_or_multiple) {
        this.bomCharValue.code.splice(key + 1, 0, 1);
        this.bomCharValue.bom_characteristic_name.splice(key + 1, 0, bom_characteristic_name)
        this.bomCharValue.bom_type_characteristic_code.splice(key + 1, 0, bom_type_characteristic_code)
        this.bomCharValue.bom_code.splice(key + 1, 0, bom_code)
        this.bomCharValue.single_or_multiple.splice(key + 1, 0, single_or_multiple)
        this.bomCharValue.characteristic_value.splice(key + 1, 0, '')
      },
      removeMultipleChar(key){
        this.bomCharValue.code.splice(key,1);
        this.bomCharValue.bom_characteristic_name.splice(key,1)
        this.bomCharValue.bom_type_characteristic_code.splice(key,1)
        this.bomCharValue.bom_code.splice(key,1)
        this.bomCharValue.single_or_multiple.splice(key,1)
        this.bomCharValue.characteristic_value.splice(key,1)
      },
      saveChar(){
        let context      = this;
        let formData     = new FormData();
        this.charValueForm.loadingSave = true;

        if(this.bomCharValue.bom_type_characteristic_code.length > 0){
          for(var i = 0;i < this.bomCharValue.bom_type_characteristic_code.length;i++){
            formData.append('type_characteristic_code['+i+']', this.bomCharValue.bom_type_characteristic_code[i])
            formData.append('characteristic_value['+i+']', this.bomCharValue.characteristic_value[i])
            formData.append('bom_code['+i+']', this.bomCharValue.bom_code[i])
            formData.append('code['+i+']', this.bomCharValue.code[i])
          }

          Api(context, bomMaster.saveChar(formData, context.bom.bom_code)).onSuccess((response) =>{
             context.$notify({
                  message: response.data.message,                  
                  type: 'success',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
              });
              context.charValueForm.loadingSave = false;
          }).onError((error) => {
             context.$notify({
                  message: error.response.data.message,                  
                  type: 'danger',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
              });
              context.charValueForm.loadingSave = false;
          }).call();
        } else {
          this.$notify({
            message:this.tt('characteristic_bom_empty'),
            type:'danger',
            verticalAlign:"bottom",
            horizontalAlign:"left"
          });
          this.charValueForm.loadingSave = false;
          return false;
        }
      },
      filterBom() {
        setTimeout(() => {                    
            this.get()
        }, 100);
      },
      querySearchType(query){
        clearTimeout(this.selectSearch.loadTimeout);

        let context = this;
        this.selectSearch.loading = true;

        this.selectSearch.loadTimeout = setTimeout(() => {
            Api(context, bomType.get(null, {per_page: 'none',search: query})).onSuccess((response) =>{
                context.bomTypes = response.data.data.data.data;
            }).onError((error) =>{
                context.bomTypes = []
            }).onFinish(() =>{
                context.selectSearch.loading = false;
            }).call();
        })
      },
    },
  };
</script>

<style>
/* .el-tree-node.is-current.is-focusable{
  background-color: #ffe391 !important;
} */
/*.el-table__body tr.current-row>td{
    background-color: #ffe391 !important;
}*/
/* .col-6.border-right.border-bill{
    border-color:1px solid #DDDDDD;
   background: linear-gradient(to right, transparent 0%, transparent calc(50% - 0.81px),  #DDDDDD calc(50% - 0.8px),  #DDDDDD calc(50% + 0.8px), transparent calc(50% + 0.81px), transparent 100%); 
   min-height:450px;
} */

/*.col-6.border-right.bom{
  padding-right:0px;
}
.col-6.border-left.bom{
  padding-left:0px;
}*/
/*.pointer{
  cursor:pointer;
}
.customTable td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.bom .el-input__inner {
  max-height:20px;
}
.bom .el-select .el-input .el-input__inner{
  max-height:20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}  */
</style>
